import React from "react";
import { Hero } from "../components";
import { Layout } from "../layouts";

const Page404: React.FC = () => {
  return (
    <Layout pagePath="/" title="404">
      <Hero
        title="We could not find the page you are looking for"
        short={false}
        className="bg-dark-blue"
        showSearch={true}
      />
    </Layout>
  );
};

export default Page404;
